<template>
	<div v-if="!loading" id="history">
		<header-service :service="service" currentTab="Histórico">
			<div slot="actions"></div>
		</header-service>
		<div class="row mt-5">
			<div class="col-lg-3 col-md-4">
				<menu-service :service="service"></menu-service>
			</div>
			<div class="col-lg-9 col-md-8">
				<div class="m-portlet m-portlet--full-height">
					<div class="m-portlet__head">
						<div class="m-portlet__head-caption">
							<div class="m-portlet__head-title">
								<h3 class="m-portlet__head-text">Histórico</h3>
							</div>
						</div>
					</div>
					<div class="m-portlet__body">
						<div v-if="!statusHistories.length">
							<div class="row">
								<div class="col-12 text-center">
									<h5>Este serviço não possui histórico.</h5>
								</div>
							</div>
						</div>
						<div v-else class="m-scrollable" style="overflow-y: visible;">
							<!--Begin::Timeline -->
							<div class="m-timeline-2">
								<div class="m-timeline-2__items m--padding-top-25">
									<div
										v-for="(history, historyIndex) in statusHistories"
										:key="historyIndex"
										class="m-timeline-2__item mb-4"
									>
										<span
											class="m-timeline-2__item-time"
											style="font-size:12px; margin-top:4px"
										>{{ $helper.convertDatePtBr(history.created_at) }}</span>
										<div class="m-timeline-2__item-cricle">
											<i :class="`fa fa-genderless m--font-${badge(history)} `"></i>
										</div>
										<div class="m-timeline-2__item-text m--padding-top-5">
											{{ `${history.user.first_name} ${history.user.last_name}` }}
											<br />
											<div v-html="history.message"></div>
											<div v-html="history.description"></div>
											<div v-if="history.files.length" class="m-list-pics">
												<br />
												<div class="mb-3" v-for="(file, fileIndex) in history.files" :key="fileIndex">
													<a class="link" :href="file.url_path" target="_blank">
														<i class="fa fa-file mr-2"></i>
														{{ file.name }}
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import HeaderService from "../components/HeaderService";
	import MenuService from "../components/MenuService";
	import ServiceService from "../services/ServiceService";

	export default {
		name: "History",
		data() {
			return {
				loading: true,
				service: {},
				statusHistories: []
			};
		},
		components: {
			HeaderService,
			MenuService
		},
		created() {
			if (this.validateACL()) {
				this.serviceService = new ServiceService();
				this.getServiceHistory();
			}
		},
		methods: {
			getServiceHistory() {
				this.serviceService
					.getServiceHistory(this.$route.params.id)
					.then(response => {
						this.service = response.data.service;
						this.statusHistories = this.service.status_histories;
						this.loading = false;
					});
			},
			validateACL() {
				if (!this.$authorization.can("service-history")) {
					history.go(-1);
					return false;
				}

				return true;
			},
			badge(history) {
				switch (history.action) {
					case "updating":
						return "info";
					case "under-analysis":
						return "focus";
					case "under-analysis-claro":
					case "suspended":
						return "warning";
					case "complete":
					case "active":
					case "approved":
					case "online":
						return "success";
					case "disapproved":
					case "inactive":
					case "disabled":
						return "primary";
					case "setup":
						return "metal";
					case "homolog":
					case "legacy":
						return "accent";
					default:
						return "metal";
				}
			}
		}
	};
</script>

<style scoped>
	.search-icon {
		color: #36a3f7 !important;
	}
	.download-icon {
		color: #34bfa3 !important;
	}
	.m-timeline-2:before {
		left: 10.89rem;
	}
	.m-timeline-2__item-cricle {
		margin-left: 6.1rem !important;
	}
	.link {
		color: #97989c !important;
		cursor: pointer;
	}
	.link:hover {
		text-decoration: none;
	}
</style>
